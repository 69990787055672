
/* app */
body {
    .default-body;

    header {
        .header;
    }

    footer {
        .footer;
    }
}

/* Common element styles */

html {

    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    min-width: 800px;
    zoom: 1;

    textarea {
        width: 100%;
    }

    .fidal-title {
        font-family: Arial;
        font-size: 2.1em;
    }

    .fidal-jumbotron {
        background-color: #697D92;        
        padding: 8px 20px 8px 20px;
        text-align: center;
        border-radius: 4px;

        .title {
            font-size: 1.32em;
            color: #fff;
        }
    }

    .textwrapper {
        border: 1px solid #999999;
        margin: 5px 0;
        padding: 3px;
    }

    .mat-paginator {
        background-color: transparent !important;
        padding: 0px !important;
        margin: auto !important;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #697D92;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background .highlighted {
        background-color: #697D92 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        background-color: white;
        border-radius: 5px;
    }

    .editable .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-outline {
        color: #9e9e9e;
    }

    .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
        color: #222222;
    }

    .mat-form-field.mat-focused .mat-form-field-outline {
        color: #697D92 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #BCBCBC;
    }

    .clickable .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #527BFF;
    }

    .mat-form-field-label {
        background-color: transparent !important;
        color: #4C387F;
        font-size: 1.2em;
    }

    .button-secondary {
        border: 1px solid #9e9e9e;
        font-size: 0.8em;

        &:hover {
            border: 1px solid #222222;
        }        
    }

    .highlighted .mat-form-field-label {
        background-color: transparent !important;
        color: #697D92;
        font-size: 1.3em;
    }

    .mat-form-field-empty.mat-form-field-label {
        color: #4C387F;
        font-size: 1em;
    }

    .tab-card .mat-tab-label:last-child {
        margin-left: auto;
    }

    .mat-tab-label:hover:not(.mat-tab-disabled) {
        background-color: #DEEFEC; 
      }

    .mat-tab-label, .mat-tab-link {
        color: #21272D !important;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 0.9em;
        background-color: transparent;
        border-radius: 5px;

        &:hover {
            background-color: #FFF7F7;
        }
    }

    .mat-toolbar.mat-exception {
        background: #FFFFFF;
        color: #122235;
    }    

    .mat-toolbar.mat-information {
        background: #218aff;
        color: #fff;
    }

    .mat-toolbar.mat-success {
        background: #5BC236;
        color: #fff;
    }
    
    .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #697D92;
        color: #DEEFEC !important;
    }

    .button {
        border-radius: 4px;
        border: 0px;
        display: inline-flex;
        justify-content: center; /* center the content horizontally */
        align-items: center; /* center the content vertically */
        cursor: pointer;

        // screen-md
        @media screen and (min-width: 760px) {
            padding: 0px 12px 0px 12px !important;
            height: 2.8em;
            min-height: unset!important;
            font-size: 0.9em;
            line-height: 1em; // DO NOT FORGET IT !
        }
        
        // screen-sm
        @media screen and (max-width: 759px) {
            padding: 0px 8px 0px 8px !important;
            height: 2.5em;
            min-height: unset!important;
            font-size: 0.8em;
            line-height: 0.7em; // DO NOT FORGET IT !
        }

        &:hover {
            background-color: #3A7E84 !important;
            color: #fff !important;
        }

        &:disabled {
            color: #8E8E8E;
            background-color: #EFEFEF;
        }
    }

    .nav-primary {
        .button;
        background-color: #697D92;
        color: #fff;
    }

    .primary {
        .button;
        background-color: #122235;
        color: #fff;
    }

    .secondary {
        .button;
        background-color: #697D92;
        color: #FFFFFF;
    }

    .classic {
        .button;
        background-color: #FFFFFF;
        color: #122235;
        border: 1px solid #747474 !important;

        &:hover {
            border: 1px solid transparent !important;
        }
    }

    .light {
        opacity: 40%;
    }
 
    .normal {
        .button;
        background-color: transparent;
        color: #000000;
    }

    .primary-text {
        background-color: transparent;
        color: #122235;
        font-size: 0.8em;
    
        &:hover {
            color: #3A7E84;
        }
    }
    
    .secondary-text {
        background-color: transparent;
        color: #697D92;
        font-size: 0.8em;

        &:hover {
            background-color: #DEEFEC;
            color: #3A7E84;
        }
    }

    .lighter {
        color: #AEAEAE;
    }

    mat-card-title {
        width: fit-content;
        display: inline-block;
        vertical-align: middle;

        .mat-icon {
            font-size: 1.4em;
            margin-right: 0.4em;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

    a {
        color: #2F7165;
        text-decoration: none;
        font-size: 1.12em;

        &:hover {
            color: #2F7165;
            text-decoration: underline;
        }
    }

    img {
        text-decoration: none !important;
        border: 0px !important;
        outline: none;
        border-width: 0px;
        outline-width: 0px;
        border-bottom: none;
        transform: scale(0.80);
    }

    table {
        width: 100%;
    }

    .spacer-item {
        flex: 1 1 auto;
    }
}

.overflow-auto {
    overflow: auto;
}

.mp-0 {
    padding: 0px !important;
    margin: 0px !important;
}

.screen-sm {
    display: none !important;

    @media screen and (max-width: 599px) {
        display: block !important;
    }
}

.screen-md {
    display: none !important;

    @media screen and (min-width: 600px) {
        display: block !important;
    }
}

.screen-lg {
    display: none !important;

    @media screen and (min-width: 760px) {
        display: block !important;
    }
}

.screen-lg-none {
    display: block !important;

    @media screen and (min-width: 760px) {
        display: none !important;
    }
}

.screen-xl {
    display: none !important;

    @media screen and (min-width: 960px) {
        display: block !important;
    }
}

.screen-xl-none {
    display: block !important;

    @media screen and (min-width: 960px) {
        display: none !important;
    }
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pane-center {
    left: 50%;
    top: 50%;
    position: absolute;
}

.on-top {
    z-index: 500;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #122235;
}

.text-alert {
    color: #F49838;
}

.pointable {
    cursor: pointer;
}

.button-small {
    zoom: 0.9;
    padding: 0em !important;
}

.text-small {
    font-size: 0.8em;
}

.dialog-text-small {
    font-size: 0.7em;
}

.cal-week-view .cal-event {
    color: #fff !important;
}

.default-body {

    background-color: #F5F5F5;
    font-family: Arial;
    color: #444;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    input:focus, select:focus, textarea:focus, button:focus {
        outline: none !important;
        text-decoration: none;
    }

    form {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .form-group.required .control-label:after {
        content: "*";
        color: red;
    }

    *:focus {
        outline: 0;
    }

    :active {
        outline: none !important;
    }
}

// Drag & drop

.uploadfilecontainer {
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center;
    height: 140px;
    width: 100%;
    margin-bottom: 20px;
    border: 2px dashed #697D92;
    border-radius: 10px;
    text-align: center;
    padding-top: 40px;
}

.fileover {
    cursor: pointer;
    background-color: #F8F7F8;
    opacity: 0.8;

    &:hover {
        background-color: #FFF7F7 !important;
    }
}

.files-list {
    justify-content: space-between;
    width: 80%;
    background: #ffffff;
    border: 1px dashed #AEAEAE;
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 8px 15px 10px 20px;
    font-size: 1.2em;
}

.progress-bar {
    height: 7px;
    width: 100%;
    border-radius: 4px;
    background-color: #d0d0d0;
    position: relative;

    .progress {
        width: 0;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border-radius: 4px;
        background-color: #4c97cb;
        transition: 0.5s all;
    }
}

.intro {
    .screen-center;
    .on-top;
    text-align: center;

    border: 1px solid #CECECE;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 40px 30px 20px 30px;

    width: 340px;
    height: 220px;
    font-size: 0.8em;

    .status {
        color: #697D92;
    }

    .logo {
        background-image: url('../img/logo_fidal.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: 50px;
        background-position: center;
        width: 230px;
        height: 60px;
        margin: auto;
        margin-bottom: 4px;
    }
}

.zoomed {
    zoom: 0.82;
}

.centered {
    margin: auto;
}

