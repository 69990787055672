/* Content element styles */

@import "common.less";

/* content */

.content {
    position: absolute;
    top: 72px;
    bottom: 20px;
    padding: 8px 20px 0px 20px;
    overflow: hidden;
    width: 100%;
    font-size: 0.9em;

    .content-divider {
      height: 4px;
      background-color: #EFEFEF;
      width: 100%;
      margin: 25px 0px 25px 0px;
    }

    .content-pane-home {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 0px;
      padding-right: 10px;
      overflow: auto;
    }

    .content-pane {
      position: absolute;
      top: 30px;
      left: 20px;
      right: 0px;
      bottom: 25px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      overflow: auto;

      @media screen and (max-width: 759px) {
        top: 16px !important;
      }
  
      .inner {
        flex-grow : 1;
        overflow: auto;
      }
  
      .bottom {
        position: absolute;
        bottom: 0px;
      }
    }
    
    .h-100-outer {
      display: flex;
      flex-flow: column;
      overflow: hidden;
      height: 100%;

      .inner-header {
        flex : 1;
      }

      .inner-top {
        flex : 2;
      }

      .inner-body {
        flex : auto;
        overflow: auto !important;
        height: 100%;     
      }

      .inner-body-noscroll {
        flex : auto;
        overflow: hidden;
        height: 100%;        
      }
    }

    .h-100-outer-box {

      .h-100-outer;
      .box-dashboard;
    }

    .area-dashboard {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .box-dashboard {
        border: 1px solid #CECECE;
        background-color: #FFFFFF;
        border-radius: 4px;
        padding: 20px 30px 20px 30px;
        margin: 0px 16px 16px 0px;

        .title {
            font-size: 0.9em;
            font-weight: bold;
        }
    }

    .box-menu {
        padding: 10px 0px 20px 0px;

        .title {
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    .tab-pane-classic {
      font-size: 0.68em;
      background-color: #FFFFFF;
      border-left: 1px solid #CECECE;
      border-bottom: 1px solid #CECECE;
      border-right: 1px solid #CECECE;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 2.5em 2em 2em 2em;
    }

    .tab-pane-queries {
      background-color: #FFFFFF;
      border-left: 1px solid #CECECE;
      border-bottom: 1px solid #CECECE;
      border-right: 1px solid #CECECE;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 1.5em 1em 2em 1em;
    }

    .filter-pane-queries {
      .on-top;
      top: 80px;
      left: 15px;
      width: 98%;
      position: absolute;
      font-size: 0.82em;
      border: 1px solid #555555;
      background-color: #FFFFFF;
      border-radius: 5px;
      padding: 16px 10px 16px 10px;
      margin-bottom: 8px;

      .filtre-clause-root {
        display: flex;
        padding-left: 0px;        
      }

      .filtre-tabbed {
        padding-left: 8px;
      }

      .filtre-clause {
        display: flex;
        flex-direction: row;        
      }

      .filtre-condition {
        display: flex;
        flex-direction: row;
      }

      .filtre-instruction {
        flex: 1;
        padding-right: 5px;        
        @media screen and (min-width: 600px) {
          max-width: 50px;
        }
      }

      .filtre-column {
        flex: 1;
        padding-right: 5px;
        @media screen and (min-width: 600px) {
          min-width: 280px;
        }
      }

      .filtre-operator {
        flex: 1;
        padding-right: 5px;
        @media screen and (min-width: 600px) {
          min-width: 120px;
        }
      }

      .filtre-value {
        flex: auto;
      }

      .filtre-star {
        width: 140px;
      }
    }

    .filter-pane {
      .on-top;
      position: absolute;
      top: 70px;
      left: 0px;
      border: 1px solid #555555;
      background-color: #FFFFFF;
      border-radius: 5px;
      padding: 10px 10px 20px 10px;

      // screen-md
      @media screen and (min-width: 760px) {
        width: 75%;
      }

      // screen-sm
      @media screen and (max-width: 759px) {
        width: 90%;
      }
    }

    .snap-pane {
      font-size: 0.81em;
      background-color: #fff;
      border: 1px solid #CECECE;
      border-radius: 5px;
      padding: 2em 2em 3em 2em;
    }

    .card-pane {
      font-size: 0.80em;
      background-color: #F8F7F8;
      border: 1px solid #CECECE;
      border-radius: 5px;
      padding: 2em 2em 3em 2em;

      // screen-md
      @media screen and (min-width: 960px) {
        width: 60%;
      }

      // screen-sm
      @media screen and (max-width: 959px) {
        width: 100%;
      }
    }

    .small  {
      font-size: 1.35em;
    }

    .l-0 {
      padding-left: 0px;
      margin-left: 0px;
    }

    .r-0 {
      padding-right: 0px;
      margin-right: 0px;
    }

    .large  {
      font-size: 1.8em;
    }

    .box-link {
      background-color: white;
      color: #122235;

      &:hover {
          background-color: #DEEFEC;
      }

      .mat-icon {

        // screen-md
        @media screen and (min-width: 960px) {
          font-size: 1.2em !important;
        }

        // screen-sm
        @media screen and (max-width: 959px) {
          font-size: 1.0em !important;
        }
      }

      span {
        // screen-md
        @media screen and (min-width: 960px) {
          font-size: 1.0em !important;
        }

        // screen-sm
        @media screen and (max-width: 959px) {
          font-size: 0.8em !important;
        }
      }
    }

    .icon-text {
      background-color: transparent;
      color: #697D92;
    }

    .icon-small {
      font-size: 0.2em;
    }

    .breadcrumb {
        background: transparent;
        font-size: 0.75em;
        margin: 0;
        padding: 0;
        
        a {
          color: #747474;
        }
        span {
          color: #747474;
          font-size: 1.15em;
        }
        a:hover {
          color: #122235;
          text-decoration: none;
        }
        li {
          list-style: none;
          float: left;
          margin-right: 5px;
          span {
            color: #747474;
          }  
        }
        li:last-child {
          margin-right: 20px;
        }
        li::after {
          content: " /";
          color: #747474;
        }
        li:last-child::after {
          content: "";
        }
    }
      
    .text-highlighted {
        color: #697D92;
    }

    .separator {
        background: #F5F5F5;
        height: 5px;
    }

    .text-normal {
        font-size: 1.2em;
    }

    .link-sm {
        font-family: Arial;
        font-size: 1.05em;
        line-height: 1.4em;
    }

    h1 {
      font-family: 'Open Sans';
      padding: 0px 0px 20px 0px;
      display: block;
      font-size: 1.25em;  
    }

    h2 {
      font-family: Arial;
      font-size: 1.25em;
      padding: 0px 0px 10px 0px;
      display: block;
    }

    h3 {
      font-family: Arial;
      font-size: 0.95em;
      font-weight: bold;
      padding: 0px 0px 5px;
      display: block;
    }

    .th-title {
      padding-top: 11px;
    }

  // precode

  // Data table

  .mat-column-code {
    flex: 0 0 75px;
  }

  .mat-cell {
    font-size: 0.9em;
}

  .data-table {
    width: 100%;
    overflow: auto;    
  
    table {
      border-left: 1px solid #CECECE;
      border-bottom: 1px solid #CECECE;
      border-right: 1px solid #CECECE;
      width: 100%;
    }

    td {
      padding-left: 12px;
      border-right: #EAEAEA 1px solid;
    }

    th {
      background-color: #697D92 !important;
      border-right: #EFEFEF 1px solid;
      padding-left: 12px;
      color: #FFFFFF;
      font-size: 1.1em;
    }

    tr.mat-row {
      .pointable;

      &:hover{
        background-color: #DEEFEC;
      }
    }
    .mat-row:nth-child(even){
      background-color: #FFFFFF;
    }
            
    .mat-row:nth-child(odd){
        background-color: #F8F7F8;
    }
    
    .mat-table-sticky:first-child {
      width: 60px;
      padding-top: 6px;
    }
    
    td.mat-column-star {
      width: 20px;
      padding-right: 20px;
    }

    .highlighted {
      background: #E5DAB0 !important;
    }    
  }

  // main-menu

  .main-header {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .main-menu {
    display:flex;

    .right {
      margin-left: auto;
    }
  }
  
  // dossier-image

  .image-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .image-box {
    border: 1px solid #DADADA;
    background-color: white;
    width: 110px;
    height: 110px;
    font-size: 0.8em;
    vertical-align: middle;
    display: inline-block;    
  }

  .image-box-image {
    border: 1px solid transparent;
    width: 90px;
    font-size: 0.4em;
    text-align: center;
    margin: 10px 0px 0px 10px;

    img {
      max-width: 90px;
      max-height: 60px;
      border: 1px solid #EFEFEF;     
    }   
  }

  .image-box-none {
    width: 110px;
    height: 110px;
    border: 1px solid #EFEFEF;
  } 

  .image-box-add {
    border: 1px solid #9e9e9e;
    background-color: #ffffff;
    width: 110px;
    height: 110px;
    font-size: 1.2em;

    &:hover {
        border: 3px solid #222222;
    }       
  }

  .cal-month-view {

    .cal-day-cell  {
      background-color: #FFFFFF !important;

      &:hover {
        background-color: #DEEFEC !important;
      }
    }

    .cal-open-day-events {
      color: #AEAEAE !important;
      font-size: 0.92em;
      padding: 15px;
      background-color: #FFFFFF;
      border-top: 1px solid #AEAEAE;
      border-bottom: 1px solid #AEAEAE;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .cal-open-day-events .cal-event {
        display: inline-block !important;
        position: static !important;
      }

      .cal-event-title {
        cursor: pointer;
        margin-right: 2em;

        &:hover {
          text-decoration: underline;
        }
      }      
    }    
  }
}
