/* Header element styles */

@import "common.less";

/* header */
.header {
    color: #444444;
    position: absolute;
    height: 72px;
    width: 100%;
    background-color: #EFEFEF;
    font-size: 0.95em;
    .on-top;

    a {
        color: #444444;
        text-decoration: none;
        //font-size: 0.94em;

        &:hover {
            text-decoration: underline;
        }
    }

    .logo {
        float: left;
        background-color: #EFEFEF;
        height: 44px;
        background-image: url('../img/logo_fidal.png');
        background-repeat: no-repeat;
        cursor: pointer;

        width: 130px;
        height: 36px;
        background-size: 100px;
        background-position-x: 16px;
        background-position-y: 4px;
    }

    .staging {
        background-color: #122235 !important;
    }

    .top-bar {
        padding: 0px 15px 0px 0px;
        height: 36px;
        font-size: 0.78em;
        font-family: Arial;
        background-color: #122235;

        .app-title {
            float: left;
            color: white;
            padding: 10px 0px 0px 30px;
            //font-size: 0.90em;
        }

        .menu {
            float: right;
            color: white;
            padding-top: 0px;

            button {
                font-size: 0.95em;
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            .transparent {
                background-color: transparent;

                &:hover {
                    background-color: #F49838;
                }
            }
        }
    }

    .menu-bar {

        @media screen and (min-width: 760px) {
            margin-left: 115px;
        }    

        @media screen and (max-width: 759px) {
            margin-left: 0px;
        }

        .toolbar {
            background-color: #EFEFEF;
            height: 36px;

            .icon-item {
                padding: 2px 14px;
            }
            
            .mat-icon {
                font-size: 1.4em;
            }
              
            .spacer-item {
                flex: 1 1 auto;
            }

            button {
                background-color: transparent;
                color: #122235;
                font-family: 'Open Sans';
                font-weight: 600;
                border-radius: 0px;

                &:hover {
                    background-color: #DEEFEC;
                }
            }
        }

        .highlighted {
            background-color: transparent;
            color: #3A7E84!important;
        }        
    }
}
