/* Footer element styles */

@import "common.less";

/* footer */
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 34px;
    text-align: center;
    background-color: #D3CDD3;
    color: #444444;
    padding: 6px 20px 0px 20px;
    font-family: Arial;
    font-size: 0.75em;
    display: table-row;

    a {
        text-decoration: none;
        color: #5E5E5E;
        font-family: Arial;

        &:hover {
            color: #5E5E5E;
            text-decoration: underline;
        }
    }

    .section {
        max-width: 850px;
        margin: auto;
    }

    .section-block {
        .section;
        display: flex;
        justify-content: center;
        .screen-md;

        .column {
            display: flex;
            flex-direction: column;
            vertical-align: top;
            text-align: left;

            .title {
                padding: 0px 40px 5px 0px;
                font-weight: bold;
                color: #BBC0C4;
            }

            .item {
                padding: 10px 40px 0px 0px;
            }
        }
    }
}
